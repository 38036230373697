import React from "react";
import passengerIcon from "../assets/passenger_icon.png";
import driverIcon from "../assets/driver_icon.png";

function FeaturesPage() {
  return (
    <div className="bg-white w-full h-[130vh] md:h-screen">
      <div className="h-full md:h-screen grid grid-rows-2 md:grid-cols-2">
        {/* Feature 1 Grid Column */}
        <div className="relative h-full md:h-screen grid items-end">
          {/* Contents Column */}
          <div className="h-full md:h-screen flex flex-col justify-end">
            <div
              className="relative flex flex-col justify-start items-center w-[88%] h-[65%] pt-[5%] px-[10%]
              mb-[15%] sm:mb-[9%] md:mb-[20%] lg:mb-[15%] xl:mb-[12%] 
              self-center border-[#C1BFC0] border-2 rounded-[40px] md:rounded-[80px]"
            >
              <p className="w-[65%] text-center text-[#3D3D3D] text-2xl md:text-3xl lg:text-4xl 3xl:text-5xl font-poppins font-semibold">
                Find Rides Anywhere
              </p>
              <p
                className="w-[110%] sm:w-[65%] md:w-[65%] text-center md:text-right text-[#C1BFC0] text-md md:text-md lg:text-lg 3xl:text-xl font-poppins font-semibold mt-[5%] md:mt-[8%]
              md:self-end"
              >
                Find rides anywhere, anytime around your University Campus, and
                beyond!
              </p>
            </div>
            <div className="relative w-full h-[12%] md:h-[15%] bg-[#FE6F04] mb-[3%]"></div>
          </div>

          <img
            src={passengerIcon}
            alt="Passenger"
            className="absolute h-1/2 object-cover justify-self-center mr-[10%]
            mb-[8%] sm:mb-[7%] md:mb-[17%] lg:mb-[14%] xl:mb-[12%]"
          />
        </div>

        {/* Feature 2 Column */}
        <div className="relative h-full md:h-screen grid items-end">
          {/* Contents Column */}
          <div className="h-full md:h-screen flex flex-col justify-end">
            <div
              className="relative flex flex-col justify-start items-center w-[88%] h-[65%] pt-[5%] px-[10%]
              mb-[18%] sm:mb-[9%] md:mb-[20%] lg:mb-[15%] xl:mb-[12%] 
              self-center border-[#C1BFC0] border-2 rounded-[40px] md:rounded-[80px]"
            >
              <p className="w-[65%] md:w-[82%] xl:w-[60%] text-center text-[#3D3D3D] text-2xl md:text-3xl lg:text-4xl 3xl:text-5xl font-poppins font-semibold">
                Bargain for the Best Price
              </p>
              <p
                className="w-[100%] sm:w-[70%] md:w-[60%] xl:w-[55%] text-center md:text-right text-[#C1BFC0] text-md md:text-md lg:text-lg 3xl:text-xl font-poppins font-semibold mt-[5%] md:mt-[8%]
                md:self-end"
              >
                No presets, suggestions only. You decide your own price.
              </p>
            </div>
            <div className="relative w-full h-[12%] md:h-[15%] bg-[#FE6F04] mb-[3%]"></div>
          </div>
          <img
            src={driverIcon}
            alt="Driver"
            className="absolute object-cover justify-self-center py-[1%] max-h-[50%] 
            md:scale-150 lg:scale-[1.2] xl:scale-100 2xl:scale-100 3xl:scale-110 
            ml-[20%] md:ml-[70%] lg:ml-[50%] xl:ml-[30%]
            mb-[13%] sm:mb-[7%] md:mb-[30%] lg:mb-[19%] xl:mb-[12%] 2xl:mb-[13%]"
          />
        </div>
      </div>
    </div>
  );
}

export default FeaturesPage;
