import React, { useState, useEffect } from 'react';
import popupLogo from '../assets/popup_logo.png'

const Popup = ({ firstMsg, secondMsg, counter }) => {
  const [firstMessage, setFirstMessage] = useState("");
  const [secondMessage, setSecondMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    if (counter > 0) {
      setFirstMessage(firstMsg);
      setSecondMessage(secondMsg);
      setShowPopup(true);

      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [counter]);

  return (
    <div className={`fixed inset-0 flex items-center justify-center z-50 ${showPopup ? 'fade-in' : 'hidden'}`}>
      <div className={`bg-white p-6 rounded-lg shadow-lg max-w-md flex flex-col items-center justify-center w-4/5 sm:w-1/3 sm:h-1/3 md:w-1/2 md:h-1/3 lg:w-2/3 lg:h-1/2 ${showPopup ? '' : 'hidden'}`}>
        <img src={popupLogo} alt="Your Image" className="object-cover w-1/2 h-1/2 mb-2" />
        <span className="text-gray-700 text-md sm:text-lg md:text-xl font-poppins font-semibold text-center max-w-full break-all">{firstMessage}</span>
        <span className="text-gray-600 text-sm sm:text-md md:text-lg font-poppins font-semibold text-center max-w-full break-all">{secondMessage}</span>
      </div>
    </div>
  );
};

export default Popup;
