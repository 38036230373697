import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import mailIcon from "../assets/mail.png";
import instagramIcon from "../assets/instagram.png";
import telegramIcon from "../assets/telegram.png";
import facebookIcon from "../assets/facebook.png";
import discordIcon from "../assets/discord.png";

function Footer() {
  return (
    <div className="bg-[#FE6F04] h-[35vh] md:h-[25vh]">
      <div className="grid grid-cols-2 h-full place-content-center overflow-hidden">
        {/* Container for Logo and Text */}
        <div className="flex flex-row pl-6 md:pr-10 items-center self-start md:self-end md:justify-end md:border-r-2 -mt-[2%]">
          {/* Logo */}
          <img src={logo} alt="Logo" className="w-14 h-14 mr-2" />
          {/* Text */}
          <span className="text-white text-2xl md:text-3xl font-poppins font-semibold">
            UniFej
          </span>
        </div>
        {/* Container for Icons */}
        <ul className="flex flex-col mr-5 md:mt-0 md:mr-0 md:pl-9 md:flex-row space-y-4 md:space-y-0 md:space-x-4 p-4 items-end">
          {/* Mail */}
          <li>
            <a href="mailto:info@unifej.com" className="text-white hover:text-gray-300">
              <img src={mailIcon} alt="Mail" className="w-6 h-auto md:w-auto md:h-6" />
            </a>
          </li>
          {/* Instagram */}
          <li>
            <a
              href="https://www.instagram.com/unifejmy/"
              className="text-white hover:text-gray-300"
            >
              <img src={instagramIcon} alt="Instagram" className="w-6 h-6" />
            </a>
          </li>
          {/* Telegram */}
          <li>
            <a href="https://t.me/+cFe6Jhompjc0Nzg1" className="text-white hover:text-gray-300">
              <img src={telegramIcon} alt="Telegram" className="w-6 h-6" />
            </a>
          </li>
          {/* Facebook */}
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=61550432708518"
              className="text-white hover:text-gray-300"
            >
              <img src={facebookIcon} alt="Facebook" className="w-6 h-6" />
            </a>
          </li>
        </ul>
        <p className="col-span-2 md:mt-8 ml-8 md:ml-0 text-xs text-white font-poppins justify-self-start md:justify-self-center">
          © {new Date().getFullYear()} UniFej. All rights reserved.
        </p>
      </div>     
    </div>
  );
}

export default Footer;
