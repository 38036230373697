import React, { useState, useEffect, useRef } from "react";
import backgroundImageLandscape from "../assets/wallpaper_gray_landscape.png";
import backgroundImagePortrait from "../assets/wallpaper_gray_portrait.png";
import axios from 'axios'; // Make sure to import Axios
import Popup from "./Popup";

function WaitlistPage() {
  const [popUpFirstMessage, setpopUpFirstMessage] = useState('');
  const [popUpSecondMessage, setpopUpSecondMessage] = useState('');
  const [counter, setCounter] = useState(0);
  const [showingPopUp, setShowingPopUp] = useState(false);

  const updateMessage = (newFirstMessage, newSecondMessage) => {
    setpopUpFirstMessage(newFirstMessage);
    setpopUpSecondMessage(newSecondMessage);
    setCounter(counter + 1)
    setShowingPopUp(true)

    setTimeout(() => {
      setShowingPopUp(false);
    }, 3000);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const emailRef = useRef(null);
  const handleEmailSubmit = () => {
    const email = emailRef.current.value;
    // Validate the email address (optional)
    if (validateEmail(email)) {
      // Send the email to your backend for storage
      sendEmailToBackend(email);
      updateMessage('Welcome onboard!', `"${email}"`)
      emailRef.current.value = ""
      
    } else {
      updateMessage('Please enter a valid email!', `Received '${email}'`)
    }
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u;
    return regex.test(email);
  };

  const [isLoading, setIsLoading] = useState(false);
	const [emailToken, setEmailToken] = useState('');

  const sendEmailToBackend = async (email) => {
    setIsLoading(true); // Set loading to true while making the request

    const credentials = {
      email: email,
    }; // DTO

    try {
      const response = await axios.post('https://landing-api.unifej.com/email/insert', credentials);
      // const response = await axios.post('https://localhost:3000', credentials);
      const { access_token } = response.data;
  
      setIsLoading(false);
      setEmailToken(access_token);
    } catch (error) {
      console.log(`Save Email Error: ${error}`);
      setIsLoading(false); 
    }
  };

  return (
    <div id="waitlist" className="mb-4 h-screen">

      {showingPopUp && <div className="absolute inset-0 bg-black opacity-50"></div>}

      <Popup firstMsg={popUpFirstMessage} secondMsg={popUpSecondMessage} counter={counter}/>
      {/* Image Background */}
      <div
        className={`bg-center bg-cover bg-no-repeat absolute w-full h-screen z-[-1] ${
          isMobile ? "bg-portrait" : "bg-landscape"
        }`}
        style={{
          backgroundImage: `url(${
            isMobile ? backgroundImagePortrait : backgroundImageLandscape
          })`,
        }}
      >
      </div>
      <div className="grid md:grid-cols-2 h-full w-full">
        {/* First Column */}
        <div className="h-full md:h-screen grid justify-center md:justify-end items-end md:items-center">
          <p className="text-7xl md:text-8xl text-center md:text-right text-white md:pr-14 leading-tight lg:leading-tight font-poppins font-semibold md:border-r-2">
            We're<br></br>working<br></br>on it.
          </p>
        </div>

        {/* Second Column */}
        <div className="h-full md:h-screen flex flex-col md:justify-center items-center md:items-start pt-[5%] md:pt-0 pb-16 md:pb-0 md:pl-28">
          <p className="w-2/3 md:w-3/4 3xl:w-2/3 text-md text-white text-center md:text-left font-poppins font-semibold pb-0 md:pr-28">
            UniFej is still currently under development. Join our waitlist to
            become one of our Beta Testers! You'll get notified when we're up.
          </p>

          <div className="relative w-3/4 md:w-3/4 3xl:w-2/3">
            <input
              id="email"
              name="email"
              type="text"
              className="peer mt-14 mb-16 md:mb-8 pl-6 h-16 w-full md:w-3/4 bg-transparent
              border-white border-2 rounded-3xl focus:outline-0
              text-md text-white font-poppins font-semibold
              placeholder-transparent
              "
              placeholder="Email Address"
              ref={emailRef}
            />

            <label
              for="email"
              className="absolute left-5 -top-4 mt-16 px-2 text-sm text-white font-poppins font-semibold bg-[#3D3D3D] rounded-3xl
            peer-focus:-top-4 peer-focus:text-sm peer-focus:bg-[#3D3D3D]
            peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-placeholder-shown:bg-transparent
            transition-all input-text"
            >
              EMAIL
            </label>
          </div>

          <p
            className="text-md text-white font-poppins font-semibold cursor-pointer"
            onClick={handleEmailSubmit}
          >
            Join Our Waitlist &gt;
          </p>
        </div>
      </div>
    </div>
  );
}

export default WaitlistPage;
