import React from 'react';
import HomePage from './components/HomePage'; 
import FeaturesPage from './components/FeaturesPage'; 
import WaitlistPage from './components/WaitlistPage';
import Footer from './components/Footer';


function App() {
  return (
    <div className='relative overflow-hidden'>
      {/* Homepage */}    
      <HomePage />
  
      {/* Features Section */}
      <FeaturesPage />
      
      {/* Waitlist Section */}
      <WaitlistPage />
      
      {/* Footer Section */} 
      <Footer />
      
    </div>
  );
}

export default App;
