import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import mailIcon from "../assets/mail.png";
import instagramIcon from "../assets/instagram.png";
import telegramIcon from "../assets/telegram.png";
import facebookIcon from "../assets/facebook.png";
import discordIcon from "../assets/discord.png";
import backgroundImageLandscape from "../assets/map_image_landscape.png";
import backgroundImagePortrait from "../assets/map_image_portrait.png";

function HomePage() {
  
  const scrollToWaitlist = () => {
    const waitlistElement = document.getElementById("waitlist"); 
    waitlistElement.scrollIntoView({ behavior: "smooth" });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="h-screen">
      {/* Colored Background */}
      <div className="bg-[#FE6F04] absolute w-full h-screen z-[-2]"></div>

      {/* Image Background */}
      <div
        className={`bg-cover bg-no-repeat absolute w-full h-screen mt-[55%] sm:mt-[15%] md:mt-0 z-[-1] ${
          isMobile ? "bg-portrait" : "bg-landscape"
        }`}
        style={{
          backgroundImage: `url(${
            isMobile ? backgroundImagePortrait : backgroundImageLandscape
          })`,
        }}
      ></div>

      {/* Navbar */}
      <nav className="bg-transparent px-6 pt-2 md:pt-6 flex items-start justify-between">
        {/* Container for Logo and Text */}
        <div className="flex items-center">
          {/* Logo */}
          <img
            src={logo}
            alt="Logo"
            className="w-11 h-11 md:w-14 md:h-14 mr-2"
          />
          {/* Text */}
          <span className="text-white text-2xl md:text-3xl font-poppins font-semibold">
            UniFej
          </span>
        </div>
        {/* Container for Icons */}
        <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 pt-4 pr-4 items-center md:items-start ">
          {/* Mail */}
          <li>
            <a href="mailto:info@unifej.com" className="text-white hover:text-gray-300">
              <img src={mailIcon} alt="Mail" className="w-auto h-6" />
            </a>
          </li>
          {/* Instagram */}
          <li>
            <a
              href="https://www.instagram.com/unifejmy/"
              className="text-white hover:text-gray-300"
            >
              <img src={instagramIcon} alt="Instagram" className="w-6 h-6" />
            </a>
          </li>
          {/* Telegram */}
          <li>
            <a href="https://t.me/+cFe6Jhompjc0Nzg1" className="text-white hover:text-gray-300">
              <img src={telegramIcon} alt="Telegram" className="w-6 h-6" />
            </a>
          </li>
          {/* Facebook */}
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=61550432708518"
              className="text-white hover:text-gray-300"
            >
              <img src={facebookIcon} alt="Facebook" className="w-6 h-6" />
            </a>
          </li>
        </ul>
      </nav>

      {/* Text */}
      <div className="h-5/6 md:grid grid-cols-4 text-white text-left">
        <div
          className="col-start-3 col-end-4 grid justify-center justify-items-center text-center
          xl:justify-start md:justify-items-start lg:grid content-center md:text-left"
        >
          <p className="pb-5 text-lg sm:text-xl md:text-4xl font-poppins font-semibold">
            Need a Ride
            <br />
            around your Campus?
          </p>
          <p className="pb-5 md:pb-5 text-6xl sm:text-7xl md:text-9xl leading-normal sm:leading-tight font-poppins font-semibold">
            Welcome<br></br>to UniFej!
          </p>
          <p
            className="text-md sm:text-lg md:text-xl font-poppins font-semibold cursor-pointer"
            onClick={scrollToWaitlist}
          >
            Join our waitlist &gt;
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
